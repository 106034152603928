var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import request from '../../api/request';
var intelligenceStore = {
    namespaced: true,
    state: {
        listsIndex: 0,
        lists: [],
        historyList: [],
        allBotList: [], // 所有列表
    },
    getters: {},
    mutations: {
        handleIndex: function (state, playload) {
            state.listsIndex = playload || 0;
        },
        setNav: function (state, playload) {
            state.lists = [];
            var datas = [];
            Array.from({ length: 20 }).forEach(function (_, index) {
                datas.push({});
            });
            Array.isArray(playload) && playload.forEach(function (el) {
                el.menuId > 2 && state.lists.push(__assign(__assign({}, el), { lists: datas, loading: 'loading' }));
            });
        },
        setLists: function (state, playload) {
            var indexs = !state.listsIndex ? state.listsIndex : JSON.parse(JSON.stringify(state.listsIndex));
            setTimeout(function () {
                state.lists[indexs].lists = playload;
                state.lists[indexs].loading = 'complete';
            }, 1000);
        }
    },
    actions: {
        getNav: function (_a, playload) {
            var state = _a.state, commit = _a.commit, dispatch = _a.dispatch;
            if (state.lists.length > 0)
                return;
            return new Promise(function (resolve, reject) {
                request.post('/app-api/lt/ai/menu/getList', {}).then(function (res) {
                    resolve(res);
                    commit('setNav', res.data);
                    dispatch('intelligence/getLists', '', { root: true });
                }).catch(function (err) {
                    reject(err);
                });
            });
        },
        getLists: function (_a, playload) {
            var state = _a.state, commit = _a.commit;
            return new Promise(function (resolve, reject) {
                var _a;
                var indexs = !state.listsIndex ? state.listsIndex : JSON.parse(JSON.stringify(state.listsIndex));
                request.post('/app-api/lt/ai/menu/getCatDetailList', {
                    menuCatId: ((_a = state.lists[indexs]) === null || _a === void 0 ? void 0 : _a.id) || ''
                }).then(function (res) {
                    resolve(res);
                    commit('setLists', res === null || res === void 0 ? void 0 : res.data);
                }).catch(function (err) {
                    reject(err);
                });
            });
        },
        getHistoryList: function (_a, playload) {
            var state = _a.state, commit = _a.commit;
            return new Promise(function (resolve, reject) {
                request.post('/app-api/lt/ai/chat/conversation/bot-list', {}).then(function (res) {
                    state.historyList = res === null || res === void 0 ? void 0 : res.data;
                    resolve(res);
                }).catch(function (err) {
                    reject(err);
                });
            });
        },
        getAllList: function (_a, playload) {
            var state = _a.state, commit = _a.commit;
            return new Promise(function (resolve, reject) {
                request.post('/app-api/lt/ai/menu/getCatDetailList', {}).then(function (res) {
                    state.allBotList = res === null || res === void 0 ? void 0 : res.data;
                    resolve(res);
                }).catch(function (err) {
                    reject(err);
                });
            });
        }
    },
    modules: {}
};
export default intelligenceStore;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ElMessage, ElMessageBox, ElNotification, ElLoading } from 'element-plus';
import axios from "axios";
import clipBoard from 'vue-clipboard3';
var toClipboard = clipBoard().toClipboard; // 一键复制
// 引入 highlight.js 的样式（代码高亮样式）
import "highlight.js/styles/github.css"; // 你可以选择其他主题
import 'katex/dist/katex.min.css';
var Feedback = /** @class */ (function () {
    function Feedback() {
        var _this = this;
        this.loadingInstance = null;
        this.fetchBinaryData = function (url) { return __awaiter(_this, void 0, Promise, function () {
            var response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.get(url, {
                                responseType: 'blob'
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Error fetching binary data:', error_1);
                        throw error_1;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
    }
    Feedback.getInstance = function () {
        var _a;
        return (_a = this.instance) !== null && _a !== void 0 ? _a : (this.instance = new Feedback());
    };
    // 消息提示
    Feedback.prototype.msg = function (msg) {
        ElMessage.info(msg);
    };
    // 错误消息
    Feedback.prototype.msgError = function (msg) {
        ElMessage.error(msg);
    };
    // 成功消息
    Feedback.prototype.msgSuccess = function (msg) {
        ElMessage.success(msg);
    };
    // 警告消息
    Feedback.prototype.msgWarning = function (msg) {
        ElMessage.warning(msg);
    };
    // 弹出提示
    Feedback.prototype.alert = function (msg) {
        ElMessageBox.alert(msg, '系统提示');
    };
    // 错误提示
    Feedback.prototype.alertError = function (msg) {
        ElMessageBox.alert(msg, '系统提示', { type: 'error' });
    };
    // 成功提示
    Feedback.prototype.alertSuccess = function (msg) {
        ElMessageBox.alert(msg, '系统提示', { type: 'success' });
    };
    // 警告提示
    Feedback.prototype.alertWarning = function (msg) {
        ElMessageBox.alert(msg, '系统提示', { type: 'warning' });
    };
    // 通知提示
    Feedback.prototype.notify = function (msg) {
        ElNotification.info(msg);
    };
    // 错误通知
    Feedback.prototype.notifyError = function (msg) {
        ElNotification.error(msg);
    };
    // 成功通知
    Feedback.prototype.notifySuccess = function (msg) {
        ElNotification.success(msg);
    };
    // 警告通知
    Feedback.prototype.notifyWarning = function (msg) {
        ElNotification.warning(msg);
    };
    // 确认窗体
    Feedback.prototype.confirm = function (msg) {
        return ElMessageBox.confirm(msg, '温馨提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        });
    };
    // 提交内容
    Feedback.prototype.prompt = function (content, title, options) {
        return ElMessageBox.prompt(content, title, __assign({ confirmButtonText: '确定', cancelButtonText: '取消' }, options));
    };
    // 打开全局loading
    Feedback.prototype.loading = function (msg, transparency) {
        if (transparency === void 0) { transparency = '0.2'; }
        this.loadingInstance = ElLoading.service({
            lock: true,
            text: msg,
            background: "rgba(255, 255, 255, ".concat(transparency, ")"),
        });
    };
    // 关闭全局loading
    Feedback.prototype.closeLoading = function () {
        var _a;
        (_a = this.loadingInstance) === null || _a === void 0 ? void 0 : _a.close();
    };
    // 复制
    Feedback.prototype.copyText = function (text, tips) {
        if (tips === void 0) { tips = ''; }
        return __awaiter(this, void 0, void 0, function () {
            var newStr, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        newStr = text.replace(/<br \/>/g, "\n") || text;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, toClipboard(newStr)];
                    case 2:
                        _a.sent();
                        if (tips)
                            this.msgSuccess(tips);
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    Feedback.prototype.downloadFile = function (datas, filename, fileType) {
        if (filename === void 0) { filename = ''; }
        if (fileType === void 0) { fileType = 'pptx'; }
        return __awaiter(this, void 0, void 0, function () {
            var blob, url, a;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.urlToBlob(datas)];
                    case 1:
                        blob = _a.sent();
                        url = URL.createObjectURL(blob);
                        a = document.createElement('a');
                        a.href = url;
                        a.download = "".concat(filename, ".").concat(fileType);
                        a.click();
                        URL.revokeObjectURL(url); // 释放资源
                        return [2 /*return*/];
                }
            });
        });
    };
    Feedback.prototype.downloadFileData = function (datas, filename) {
        if (filename === void 0) { filename = ''; }
        return __awaiter(this, void 0, void 0, function () {
            var blob, url, a;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.urlToBlob(datas)];
                    case 1:
                        blob = _a.sent();
                        url = URL.createObjectURL(blob);
                        a = document.createElement('a');
                        a.href = url;
                        a.download = this.generateFileName(datas, filename);
                        a.click();
                        URL.revokeObjectURL(url); // 释放资源
                        return [2 /*return*/];
                }
            });
        });
    };
    Feedback.prototype.getFileExtension = function (url) {
        var match = url === null || url === void 0 ? void 0 : url.match(/\.([a-z0-9]+)(\?.*)?$/i);
        if (!match)
            return null;
        return match[1].toLowerCase();
    };
    Feedback.prototype.generateFileName = function (url, defaultName) {
        var extension = this.getFileExtension(url);
        return extension ? "".concat(defaultName, ".").concat(extension) : defaultName;
    };
    Feedback.prototype.urlToBlob = function (url) {
        return __awaiter(this, void 0, void 0, function () {
            var response, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.get(url, { responseType: 'blob' })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data]; // Blob 对象
                    case 2:
                        error_3 = _a.sent();
                        console.error('Error fetching and converting to blob:', error_3);
                        throw error_3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Feedback.prototype.encodeBase64 = function (input) {
        try {
            return btoa(input);
        }
        catch (error) {
            console.error("Base64 编码失败:", error);
            return null;
        }
    };
    Feedback.prototype.random8DigitNumber = function () {
        return Math.floor(10000000 + Math.random() * 90000000);
    };
    Feedback.instance = null;
    return Feedback;
}());
export { Feedback };
var feedback = Feedback.getInstance();
export default feedback;

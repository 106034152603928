var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/images/login/close.png';
import _imports_1 from '@/assets/images/logo.png';
var _hoisted_1 = { class: "login_phone flexColumn-alc" };
var _hoisted_2 = { class: "lp_form flexColumn-alc flex-jcc" };
var _hoisted_3 = { class: "lpf_btn" };
var _hoisted_4 = { class: "lp_more flexColumn-alc flex-jcsb" };
var _hoisted_5 = { class: "lpm_name" };
import { ref } from 'vue';
import config from '@/api/config';
import { ElMessageBox } from 'element-plus';
export default /*@__PURE__*/ _defineComponent({
    __name: 'login_phone',
    props: {
        loading: { type: Boolean }
    },
    emits: ['close', 'next', 'change'],
    setup: function (__props, _a) {
        var _this = this;
        var __emit = _a.emit;
        var emits = __emit;
        var mobile = ref('');
        var checkedCities = ref([]);
        var next = function () { return __awaiter(_this, void 0, void 0, function () {
            var xy, ys, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(checkedCities.value.length == 0)) return [3 /*break*/, 5];
                        xy = "<a href=\"".concat(config.employURL, "\" target=\"_blank\" style=\"text-decoration: none;\" class=\"clickable color\">\u4F7F\u7528\u534F\u8BAE</a>");
                        ys = "<a href=\"".concat(config.privacyURL, "\" target=\"_blank\" style=\"text-decoration: none;\" class=\"clickable color\">\u9690\u79C1\u653F\u7B56</a>");
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, ElMessageBox.confirm("\u5DF2\u9605\u8BFB\u5E76\u540C\u610F ".concat(config.brand_name, " \u7684 ").concat(xy, " \u548C ").concat(ys, "\u3002"), '服务协议及隐私保护', {
                                confirmButtonText: '同意',
                                cancelButtonText: '不同意',
                                type: 'warning',
                                roundButton: true,
                                dangerouslyUseHTMLString: true,
                            })];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        return [2 /*return*/];
                    case 4:
                        checkedCities.value = ['1'];
                        _a.label = 5;
                    case 5:
                        emits('next', mobile.value);
                        return [2 /*return*/];
                }
            });
        }); };
        return function (_ctx, _cache) {
            var _component_el_input = _resolveComponent("el-input");
            var _component_el_button = _resolveComponent("el-button");
            var _component_el_checkbox = _resolveComponent("el-checkbox");
            var _component_el_checkbox_group = _resolveComponent("el-checkbox-group");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", {
                    class: "lp_close clickable",
                    onClick: _cache[0] || (_cache[0] = function ($event) { return (_ctx.$emit('close')); })
                }, _cache[5] || (_cache[5] = [
                    _createElementVNode("img", { src: _imports_0 }, null, -1)
                ])),
                _cache[10] || (_cache[10] = _createElementVNode("aside", { class: "flexColumn-alc flex-jcc lp_title" }, [
                    _createElementVNode("img", { src: _imports_1 }),
                    _createElementVNode("span", { class: "ft20 ftBold" }, "登录以解锁更多功能")
                ], -1)),
                _createElementVNode("aside", _hoisted_2, [
                    _createVNode(_component_el_input, {
                        modelValue: mobile.value,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return ((mobile).value = $event); }),
                        style: { "width": "100%" },
                        placeholder: "请输入手机号"
                    }, null, 8, ["modelValue"]),
                    _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_el_button, {
                            type: "primary",
                            disabled: !mobile.value,
                            loading: _ctx.loading,
                            onClick: next
                        }, {
                            default: _withCtx(function () { return _cache[6] || (_cache[6] = [
                                _createTextVNode("下一步")
                            ]); }),
                            _: 1
                        }, 8, ["disabled", "loading"])
                    ])
                ]),
                _createElementVNode("aside", _hoisted_4, [
                    _cache[9] || (_cache[9] = _createElementVNode("div", null, null, -1)),
                    _createElementVNode("div", null, [
                        _createVNode(_component_el_checkbox_group, {
                            modelValue: checkedCities.value,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) { return ((checkedCities).value = $event); })
                        }, {
                            default: _withCtx(function () { return [
                                _createVNode(_component_el_checkbox, { value: "1" }, {
                                    default: _withCtx(function () { return [
                                        _createElementVNode("p", _hoisted_5, [
                                            _cache[7] || (_cache[7] = _createTextVNode(" 已阅读并同意 AI助手 的 ")),
                                            _createElementVNode("span", {
                                                onClick: _cache[2] || (_cache[2] = _withModifiers(function () { }, ["stop"]))
                                            }, "使用协议"),
                                            _cache[8] || (_cache[8] = _createTextVNode(" 和 ")),
                                            _createElementVNode("span", {
                                                onClick: _cache[3] || (_cache[3] = _withModifiers(function () { }, ["stop"]))
                                            }, "隐私政策")
                                        ])
                                    ]; }),
                                    _: 1
                                })
                            ]; }),
                            _: 1
                        }, 8, ["modelValue"])
                    ])
                ])
            ]));
        };
    }
});

import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { onMounted } from 'vue';
import { useStore } from 'vuex';
export default /*@__PURE__*/ _defineComponent({
    __name: 'App',
    setup: function (__props) {
        var store = useStore();
        onMounted(function () {
            if (localStorage.getItem('token')) {
                store.commit('setHistroyList');
                store.commit('setDrawHistroyList');
                store.commit('setSimpleList');
                store.dispatch('getUserInfo');
                store.dispatch('intelligence/getHistoryList');
                store.dispatch('intelligence/getAllList');
            }
        });
        return function (_ctx, _cache) {
            var _component_router_view = _resolveComponent("router-view");
            return (_openBlock(), _createBlock(_component_router_view));
        };
    }
});

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, vShow as _vShow, withModifiers as _withModifiers, withDirectives as _withDirectives } from "vue";
import _imports_0 from '../../assets/images/logo.png';
import _imports_1 from '../../assets/images/collapse.svg';
import _imports_2 from '../../assets/images/icons/new.png';
import _imports_3 from '../../assets/images/icons/yl.png';
import _imports_4 from '../../assets/images/icons/ls.png';
import _imports_5 from '../../assets/images/handle/more.png';
import _imports_6 from '../../assets/images/icons/znt.png';
import _imports_7 from '../../assets/images/icons/add_znt.png';
import _imports_8 from '@/assets/images/logo_tt.png';
var _hoisted_1 = { class: "sidebar flexColumn" };
var _hoisted_2 = { class: "s_head flexRow-alc flex-jcsb" };
var _hoisted_3 = { class: "flexRow-alc" };
var _hoisted_4 = {
    key: 0,
    class: "ft16",
    style: { "margin-right": "10px" }
};
var _hoisted_5 = { class: "flex1 s_main" };
var _hoisted_6 = { class: "childrens" };
var _hoisted_7 = { class: "flexRow-alc flex1" };
var _hoisted_8 = ["src"];
var _hoisted_9 = { class: "ft14" };
var _hoisted_10 = { class: "childrens" };
var _hoisted_11 = { class: "childrens" };
var _hoisted_12 = { class: "flexRow-alc flex-jcsb oneBeyond flex1" };
var _hoisted_13 = { class: "oneBeyond flex1" };
var _hoisted_14 = ["onClick"];
var _hoisted_15 = { class: "childrens" };
var _hoisted_16 = { key: 0 };
var _hoisted_17 = {
    key: 0,
    class: "flexRow-alc flex1"
};
var _hoisted_18 = { class: "flexRow-alc flex1" };
var _hoisted_19 = ["src"];
var _hoisted_20 = { class: "ft14" };
var _hoisted_21 = ["onClick"];
var _hoisted_22 = {
    key: 0,
    class: "flexRow-alc s_head ft12"
};
import config from '../../api/config';
import { reactive, computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import emitter from '../../utils/eventBus';
import ycHandle from '../../components/yc-handle/yc-handle.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'sidebar',
    setup: function (__props) {
        var route = useRoute();
        var router = useRouter();
        var activeMenu = computed(function () {
            return route.fullPath;
        });
        var store = useStore();
        var isCollapse = computed(function () { return store.state.isCollapse; });
        var handleCollapse = function () {
            store.commit('handleCollapse', !isCollapse.value);
        };
        // 智能体历史
        var intelligenceHistory = computed(function () { return store.state.intelligence.historyList || []; });
        var getIntelligenceUrl = function (item) {
            return "/intelligence_chat?id=".concat(item.modelId, "&name=").concat(encodeURIComponent(item.modelName), "&historyId=").concat(item.id);
        };
        var goIntelligence = function (item) {
            if (route.name == '/intelligence_chat') {
                emitter.emit('goIntelligence', item);
            }
            var url = getIntelligenceUrl(item);
            openHandle2(-1);
            router.push(url);
        };
        var getImage = function (icon) {
            return require("../../assets/images/icons/" + icon);
        };
        var getPath = function (item) {
            if (item.modelName == 'drawing')
                return "/drawing_chat?chatid=".concat(item.id, "&viewType=").concat(item.modelName);
            if (item.model === 'image')
                return "/imagecomprehend?historyId=".concat(item.id);
            return "/chat?chatid=".concat(item.id, "&viewType=").concat(getViewType(item.modelName));
        };
        var getViewType = function (modelName) {
            if (modelName == '图片理解')
                return 'chat';
            var keys = ['drawing', 'mbti', 'translate', 'chat', 'image'];
            return keys.includes(modelName) ? modelName : 'writing';
        };
        var jump = function (routePath) {
            if (routePath === activeMenu.value)
                return;
            router.push(routePath);
        };
        var lists = reactive([
            {
                name: 'AI写作',
                icon: 'xz.png',
                routePath: '/writing'
            },
            {
                name: 'PPT生成',
                icon: 'ppt.png',
                routePath: '/pptMake'
            }
        ]);
        var histroyList = computed(function () {
            var datas = store.state.histroyList ? JSON.parse(JSON.stringify(store.state.histroyList)) : [];
            // let datas_2 = store.state.drawHistroyList ? JSON.parse(JSON.stringify(store.state.drawHistroyList)) : []
            // let drawData = datas_2.map((item: any) => {
            //   return {
            //     ...item,
            //     modelName: 'drawing',
            //     title: item.name || '新对话'
            //   }
            // })
            // 合并两个数组
            // let combinedDatas = [...datas, ...drawData]
            // combinedDatas.sort((a, b) => new Date(b.createTime).getTime() - new Date(a.createTime).getTime())
            // 截取前 4 个元素
            return datas.slice(0, 4);
        });
        var historyChange = function (item) {
            if (item.model === 'image') {
                if (route.name === '/imagecomprehend') {
                    emitter.emit('historyHandle', item);
                }
                else {
                    router.push("/imagecomprehend?historyId=".concat(item.id));
                }
                return;
            }
            var viewType = getViewType(item.modelName);
            var routePath = viewType == 'drawing' ? "/drawing_chat?chatid=".concat(item.id, "&viewType=").concat(viewType) : "/chat?chatid=".concat(item.id, "&viewType=").concat(viewType);
            if (routePath === activeMenu.value)
                return;
            var params = {};
            if (viewType == 'writing') {
                params = {
                    wirteQuery: {
                        bot: item.modelName,
                        botKey: '',
                        is_setp: false
                    }
                };
            }
            store.commit('setChat', __assign({ keyword: '', type: viewType, is_create: false }, params));
            // if (routePath.indexOf(route.name) !== -1) {
            // }
            emitter.emit('historyHandle', item);
            openHandle(-1);
            router.push(routePath);
        };
        var handleIndex = ref(-1);
        var ycHandleRef = ref();
        var openHandle = function (index) {
            var _a;
            // 重复点击关闭
            if (handleIndex.value == index && handleIndex.value !== -1) {
                (_a = ycHandleRef.value[handleIndex.value]) === null || _a === void 0 ? void 0 : _a.close();
                handleIndex.value = -1;
                return;
            }
            // 显示选择，其他关闭
            ycHandleRef.value.forEach(function (el, ind) {
                if (ind == index) {
                    el.open();
                }
                else {
                    el.close();
                }
            });
            // 拷贝上次选择
            handleIndex.value = index;
        };
        var handleIndex2 = ref(-1);
        var ycHandleRef2 = ref();
        var openHandle2 = function (index) {
            var _a;
            // 重复点击关闭
            if (handleIndex2.value == index && handleIndex2.value !== -1) {
                (_a = ycHandleRef2.value[handleIndex2.value]) === null || _a === void 0 ? void 0 : _a.close();
                handleIndex2.value = -1;
                return;
            }
            // 显示选择，其他关闭
            ycHandleRef2.value.forEach(function (el, ind) {
                if (ind == index) {
                    el.open();
                }
                else {
                    el.close();
                }
            });
            // 拷贝上次选择
            handleIndex2.value = index;
        };
        return function (_ctx, _cache) {
            var _component_el_menu_item = _resolveComponent("el-menu-item");
            var _component_el_sub_menu = _resolveComponent("el-sub-menu");
            var _component_el_menu = _resolveComponent("el-menu");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("aside", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _cache[10] || (_cache[10] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
                        (!isCollapse.value)
                            ? (_openBlock(), _createElementBlock("h1", _hoisted_4, _toDisplayString(_unref(config).brand_name), 1))
                            : _createCommentVNode("", true)
                    ]),
                    (!isCollapse.value)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "clickable",
                            onClick: handleCollapse
                        }, _cache[11] || (_cache[11] = [
                            _createElementVNode("img", {
                                src: _imports_1,
                                style: { "width": "24px", "height": "24px" }
                            }, null, -1)
                        ])))
                        : _createCommentVNode("", true)
                ]),
                _createElementVNode("aside", _hoisted_5, [
                    _createVNode(_component_el_menu, {
                        "default-active": activeMenu.value,
                        collapse: isCollapse.value,
                        "default-openeds": ['home', 'openChild', 'znt']
                    }, {
                        default: _withCtx(function () { return [
                            _createVNode(_component_el_sub_menu, { index: "home" }, {
                                title: _withCtx(function () { return _cache[12] || (_cache[12] = [
                                    _createElementVNode("div", { class: "flexRow-alc" }, [
                                        _createElementVNode("img", {
                                            src: _imports_2,
                                            class: "icons"
                                        }),
                                        _createElementVNode("span", { class: "ft14" }, "首页")
                                    ], -1)
                                ]); }),
                                default: _withCtx(function () { return [
                                    _createElementVNode("div", _hoisted_6, [
                                        _createVNode(_component_el_menu_item, {
                                            index: "/new-chat",
                                            onClick: _cache[0] || (_cache[0] = function ($event) { return (jump('/new-chat')); })
                                        }, {
                                            default: _withCtx(function () { return _cache[13] || (_cache[13] = [
                                                _createElementVNode("p", null, "新对话", -1)
                                            ]); }),
                                            _: 1
                                        }),
                                        _createVNode(_component_el_menu_item, {
                                            index: "/voicecall",
                                            onClick: _cache[1] || (_cache[1] = function ($event) { return (jump('/voicecall')); })
                                        }, {
                                            default: _withCtx(function () { return _cache[14] || (_cache[14] = [
                                                _createElementVNode("p", null, "语音通话", -1)
                                            ]); }),
                                            _: 1
                                        })
                                    ])
                                ]; }),
                                _: 1
                            }),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(lists, function (item, index) {
                                return (_openBlock(), _createBlock(_component_el_menu_item, {
                                    index: item.routePath,
                                    key: index,
                                    onClick: function ($event) { return (jump(item.routePath)); }
                                }, {
                                    default: _withCtx(function () { return [
                                        _createElementVNode("div", _hoisted_7, [
                                            _createElementVNode("img", {
                                                src: getImage(item.icon),
                                                class: "icons"
                                            }, null, 8, _hoisted_8),
                                            _createElementVNode("span", _hoisted_9, _toDisplayString(item.name), 1)
                                        ])
                                    ]; }),
                                    _: 2
                                }, 1032, ["index", "onClick"]));
                            }), 128)),
                            _createVNode(_component_el_sub_menu, {
                                index: "recreation",
                                class: "ai_more"
                            }, {
                                title: _withCtx(function () { return _cache[15] || (_cache[15] = [
                                    _createElementVNode("div", { class: "flexRow-alc" }, [
                                        _createElementVNode("img", {
                                            src: _imports_3,
                                            class: "icons"
                                        }),
                                        _createElementVNode("span", { class: "ft14" }, "AI娱乐")
                                    ], -1)
                                ]); }),
                                default: _withCtx(function () { return [
                                    _createElementVNode("div", _hoisted_10, [
                                        _createVNode(_component_el_menu_item, {
                                            index: "/drawing",
                                            onClick: _cache[2] || (_cache[2] = function ($event) { return (jump('/drawing')); })
                                        }, {
                                            default: _withCtx(function () { return _cache[16] || (_cache[16] = [
                                                _createElementVNode("p", null, "AI绘画", -1)
                                            ]); }),
                                            _: 1
                                        }),
                                        _createVNode(_component_el_menu_item, {
                                            index: "/music",
                                            onClick: _cache[3] || (_cache[3] = function ($event) { return (jump('/music')); })
                                        }, {
                                            default: _withCtx(function () { return _cache[17] || (_cache[17] = [
                                                _createElementVNode("p", null, "音乐生成", -1)
                                            ]); }),
                                            _: 1
                                        })
                                    ])
                                ]; }),
                                _: 1
                            }),
                            _cache[24] || (_cache[24] = _createElementVNode("div", { class: "line" }, null, -1)),
                            _createVNode(_component_el_sub_menu, { index: "openChild" }, {
                                title: _withCtx(function () { return _cache[18] || (_cache[18] = [
                                    _createElementVNode("div", { class: "flexRow-alc" }, [
                                        _createElementVNode("img", {
                                            src: _imports_4,
                                            class: "icons"
                                        }),
                                        _createElementVNode("span", { class: "ft14" }, "最近对话")
                                    ], -1)
                                ]); }),
                                default: _withCtx(function () { return [
                                    _createElementVNode("div", _hoisted_11, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(histroyList.value, function (item, index) {
                                            return (_openBlock(), _createBlock(_component_el_menu_item, {
                                                index: getPath(item),
                                                key: index,
                                                onClick: function ($event) { return (historyChange(item)); }
                                            }, {
                                                default: _withCtx(function () { return [
                                                    _createElementVNode("div", _hoisted_12, [
                                                        _createElementVNode("p", _hoisted_13, _toDisplayString(item.title), 1),
                                                        _createVNode(ycHandle, {
                                                            ref_for: true,
                                                            ref_key: "ycHandleRef",
                                                            ref: ycHandleRef,
                                                            datas: item,
                                                            onClose: _cache[4] || (_cache[4] = function ($event) { return (handleIndex.value = -1); }),
                                                            onInit: _cache[5] || (_cache[5] = function ($event) { return (_unref(router).push('/')); })
                                                        }, {
                                                            default: _withCtx(function () { return [
                                                                _withDirectives(_createElementVNode("div", {
                                                                    class: "flexRow-alc si_del",
                                                                    onClick: _withModifiers(function ($event) { return (openHandle(index)); }, ["stop"])
                                                                }, _cache[19] || (_cache[19] = [
                                                                    _createElementVNode("img", { src: _imports_5 }, null, -1)
                                                                ]), 8, _hoisted_14), [
                                                                    [_vShow, activeMenu.value === getPath(item)]
                                                                ])
                                                            ]; }),
                                                            _: 2
                                                        }, 1032, ["datas"])
                                                    ])
                                                ]; }),
                                                _: 2
                                            }, 1032, ["index", "onClick"]));
                                        }), 128)),
                                        _createVNode(_component_el_menu_item, {
                                            index: "/history-chat",
                                            onClick: _cache[6] || (_cache[6] = function ($event) { return (jump('/history-chat')); })
                                        }, {
                                            default: _withCtx(function () { return _cache[20] || (_cache[20] = [
                                                _createElementVNode("p", null, "查看全部", -1)
                                            ]); }),
                                            _: 1
                                        })
                                    ])
                                ]; }),
                                _: 1
                            }),
                            _cache[25] || (_cache[25] = _createElementVNode("div", { class: "line" }, null, -1)),
                            _createVNode(_component_el_sub_menu, { index: "znt" }, {
                                title: _withCtx(function () { return _cache[21] || (_cache[21] = [
                                    _createElementVNode("div", { class: "flexRow-alc" }, [
                                        _createElementVNode("img", {
                                            src: _imports_6,
                                            class: "icons"
                                        }),
                                        _createElementVNode("span", { class: "ft14" }, "智能体")
                                    ], -1)
                                ]); }),
                                default: _withCtx(function () { return [
                                    _createElementVNode("div", _hoisted_15, [
                                        _createVNode(_component_el_menu_item, {
                                            index: "/intelligence_list",
                                            onClick: _cache[7] || (_cache[7] = function ($event) { return (jump('/intelligence_list')); })
                                        }, {
                                            default: _withCtx(function () { return _cache[22] || (_cache[22] = [
                                                _createElementVNode("div", { class: "flexRow-alc" }, [
                                                    _createElementVNode("img", {
                                                        src: _imports_7,
                                                        class: "znt_icon"
                                                    }),
                                                    _createElementVNode("span", { class: "ft14" }, "发现 AI 智能体")
                                                ], -1)
                                            ]); }),
                                            _: 1
                                        }),
                                        (Array.isArray(intelligenceHistory.value) && intelligenceHistory.value.length > 0)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(intelligenceHistory.value, function (item, index) {
                                                    return (_openBlock(), _createBlock(_component_el_menu_item, {
                                                        index: getIntelligenceUrl(item),
                                                        onClick: function ($event) { return (goIntelligence(item)); },
                                                        key: index
                                                    }, {
                                                        default: _withCtx(function () { return [
                                                            (item.botDetail)
                                                                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                                                    _createElementVNode("div", _hoisted_18, [
                                                                        _createElementVNode("img", {
                                                                            src: item.botDetail.icon,
                                                                            class: "znt_icon"
                                                                        }, null, 8, _hoisted_19),
                                                                        _createElementVNode("span", _hoisted_20, _toDisplayString(item.botDetail.name), 1)
                                                                    ]),
                                                                    _createVNode(ycHandle, {
                                                                        ref_for: true,
                                                                        ref_key: "ycHandleRef2",
                                                                        ref: ycHandleRef2,
                                                                        datas: __assign(__assign({}, item), { modelData: 'znt' }),
                                                                        onClose: _cache[8] || (_cache[8] = function ($event) { return (handleIndex2.value = -1); }),
                                                                        showRename: false,
                                                                        onInit: _cache[9] || (_cache[9] = function ($event) { return (_unref(router).push('/')); })
                                                                    }, {
                                                                        default: _withCtx(function () { return [
                                                                            _withDirectives(_createElementVNode("div", {
                                                                                class: "flexRow-alc si_del",
                                                                                onClick: _withModifiers(function ($event) { return (openHandle2(index)); }, ["stop"])
                                                                            }, _cache[23] || (_cache[23] = [
                                                                                _createElementVNode("img", { src: _imports_5 }, null, -1)
                                                                            ]), 8, _hoisted_21), [
                                                                                [_vShow, activeMenu.value === getIntelligenceUrl(item)]
                                                                            ])
                                                                        ]; }),
                                                                        _: 2
                                                                    }, 1032, ["datas"])
                                                                ]))
                                                                : _createCommentVNode("", true)
                                                        ]; }),
                                                        _: 2
                                                    }, 1032, ["index", "onClick"]));
                                                }), 128))
                                            ]))
                                            : _createCommentVNode("", true)
                                    ])
                                ]; }),
                                _: 1
                            })
                        ]; }),
                        _: 1
                    }, 8, ["default-active", "collapse"])
                ]),
                (!isCollapse.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_22, _cache[26] || (_cache[26] = [
                        _createElementVNode("img", {
                            src: _imports_8,
                            style: { "width": "40px", "height": "40px", "margin-right": "6px" }
                        }, null, -1),
                        _createElementVNode("span", { class: "ftBold" }, "联通元景", -1),
                        _createElementVNode("span", {
                            class: "i_color",
                            style: { "margin-left": "5px" }
                        }, "提供技术支持", -1)
                    ])))
                    : _createCommentVNode("", true)
            ]));
        };
    }
});
